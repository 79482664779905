import './navbar.css';
import { ReactComponent as ViberIcon } from '../../assets/icons/viber.svg';
import { ReactComponent as WhatsApp } from '../../assets/icons/whatsapp.svg';
import { ReactComponent as Telegram } from '../../assets/icons/telegram.svg';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { Squash as Hamburger } from "hamburger-react";
import {useState} from "react";
import { Link, useNavigate } from 'react-router-dom';


const NavBar = () => {
    const [isOpen, setOpen] = useState(false);
    const navigate = useNavigate();

    const scrollToElement = (id) => {
        const element = document.getElementById(id);
        if (element) {
            window.scrollTo({
                behavior: 'smooth',
                top: element.offsetTop,
            });
        }
    };

    const handleNavigation = (id, path) => {
        if (location.pathname === '/') {
            scrollToElement(id);
        } else {
            navigate('/', { state: { targetId: id } });
        }
    };

    return (
        <nav>
            <div className="logo">
                <Logo />
            </div>
            <ul className='navbar-container'>
                <ul className='navbar-items'>
                    <li><Link onClick={() => handleNavigation('home', '/')} to="/">Main</Link></li>
                    <li><Link onClick={() => handleNavigation('contacts', '/')} to="/">Contacts</Link></li>
                    <li><Link onClick={() => handleNavigation('services', '/')} to="/">Services</Link></li>
                    <li><Link onClick={() => handleNavigation('projects', '/')} to="/">Our Projects</Link></li>
                </ul>
                <Hamburger toggled={isOpen} size={20} toggle={setOpen} />
                {isOpen && (
                    <ul className="responsive-navbar__items">
                        <li><Link onClick={() => handleNavigation('home', '/')} to="/">Main</Link></li>
                        <li><Link onClick={() => handleNavigation('contacts', '/')} to="/">Contacts</Link></li>
                        <li><Link onClick={() => handleNavigation('services', '/')} to="/">Services</Link></li>
                        <li><Link onClick={() => handleNavigation('projects', '/')} to="/">Our Projects</Link></li>
                    </ul>
                )}
                <ul className={'icons'}>
                    <li><ViberIcon /></li>
                    <li><WhatsApp /></li>
                    <li><Telegram /></li>
                </ul>
            </ul>
        </nav>
    );
};

export default NavBar;
