// import axios from 'axios';
// import { useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import img1 from '../assets/images/projects_images/main.png';
import img2 from '../assets/images/projects_images/about_us.png';
import img3 from '../assets/images/projects_images/try_or_buy.png'
import img4 from '../assets/images/projects_images/description.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper/modules';
import 'swiper/swiper-bundle.css';



const ImagesDetails = () => {
    // const [images, setImages] = useState([]);
    // const location = useLocation();
    // const searchParams = new URLSearchParams(location.search);
    // const paramValue = searchParams.get('id');
    // const URL = `http://127.0.0.1:8000/all-projects/${paramValue}/`;
    //
    // const allImagesData = () => {
    //     axios.get(URL)
    //         .then(response => {
    //             console.log('API Response:', response.data);
    //             setImages(response.data.detail_images);
    //         })
    // };
    //
    // useEffect(() => {
    //     allImagesData();
    // }, []);
    const slides = [
        { id: 1, image: img1, alt: "First image" },
        { id: 2, image: img2, alt: "Second image" },
        { id: 3, image: img3, alt: "Third image" },
        { id: 4, image: img4, alt: "Fourth image" },
    ];
    return (
        // <>
        //     <div className="work-images flex">
        //         <div className="images-container">
        //             {images.map((item, index) => (
        //                 <img src={item.image} key={item.id} className='work-image'/>
        //             ))}
        //         </div>
        //     </div>
        // </>
        // );

        <>
            <div className="work-images flex">
                <div className="images-container hide-images">
                    <img src={img1} className='work-image'/>
                    <img src={img2} className='work-image'/>
                    <img src={img3} className='work-image'/>
                    <img src={img4} className='work-image'/>
                </div>
                <Swiper
                    spaceBetween={250}
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    pagination={{ clickable: true }}
                    slidesPerView={1}
                >
                    {slides.map((slide) => (
                        <SwiperSlide key={slide.id}>
                            <img className='slider-images' src={slide.image} alt={slide.alt} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </>
    );
};

export default ImagesDetails;
