import './projects.css'
import { ReactComponent as BottomLeft } from '../../../assets/arrows/bottom-left.svg'
import ProjectData from "../../../helpers/project_data";

const Projects = () =>{
    return(
        <div className="projects" id='projects'>
            <div className="title">
                <h1>our projects</h1><h6>This Sunday's <span>Top 10 Design Articles</span></h6>
                <BottomLeft/>
            </div>
            <div className="grid-container">
                <ProjectData/>
            </div>
        </div>
    )
}

export default Projects