// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import { ReactComponent as TopRight } from '../assets/arrows/top-right.svg';
import { Link } from 'react-router-dom';
import project_img from '../assets/images/projects_images/Project1.png'

//
// const URL = 'http://127.0.0.1:8000/all-projects/';

const ProjectData = () => {
    // const [data, setData] = useState([]);
    //
    // const allProjectData = () => {
    //     axios.get(URL)
    //         .then((response) => {
    //             setData(response.data);
    //             console.log(response.data)
    //         })
    // };
    //
    // useEffect(() => {
    //     allProjectData();
    // }, []);

    return (
        // <div className='grid-container'>
        //     {data.slice(0, 3).map((project, index) => (
        //         <div className="element">
        //             <img src={project.demo_image}/>
        //             <div className="project-title"></div>
        //             <div className="project-buttons">
        //                 <a href={project.link}><button className='project-item'>{project.title}</button></a>
        //                 <Link to={`/view/?id=${project.id}`}><TopRight/></Link>
        //             </div>
        //         </div>
        //     ))}
        // </div>

        <div className='grid-container'>
                <div className="element">
                    <img src={project_img}/>
                    <div className="project-title"></div>
                    <div className="project-buttons">
                        <a href='https://the-boats.eu/'>
                            <button className='project-item'>the-boats</button>
                        </a>
                        <Link to='/view'><TopRight/></Link>
                    </div>
                </div>
        </div>
    );
};

export default ProjectData;
