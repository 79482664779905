import React from 'react';
import './team.css';
import { ReactComponent as BottomLeft } from '../../../assets/arrows/bottom-left.svg';
import first from '../../../assets/images/team_images/first.jpg';
import second from '../../../assets/images/team_images/second.jpg';
import third from '../../../assets/images/team_images/third.jpeg';
import fourth from '../../../assets/images/team_images/fourth.jpg';

const Team = () => {
    const images = [
        { src: first, alt: 'Team Member 1' },
        { src: second, alt: 'Team Member 2' },
        { src: third, alt: 'Team Member 3' },
        { src: fourth, alt: 'Team Member 4' },
    ];

    return (
        <div className='our-team'>
            <div className="title">
                <h1>Our team is ready to <br />perform complex tasks</h1>
                <h6>
                    With expertise spanning various fields, <span>we confidently engage cross-continental
                    experts to tackle even the most complex tasks. Through certification programs, knowledge
                    exchange, active participation in IT events, and regular assessments, we consistently
                    elevate our team's capabilities.</span>
                </h6>
                <BottomLeft />
            </div>
            <div className="slider">
                <div className="slide-wrapper">
                    {images.map((item, index) => (
                        <div key={index} className='slide-item'>
                            <img src={item.src} alt={item.alt}/>
                        </div>
                    ))}
                </div>
                <div className="slide-wrapper">
                    {images.map((item, index) => (
                        <div key={index} className='slide-item'>
                            <img src={item.src} alt={item.alt}/>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Team;
