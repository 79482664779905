import React from 'react';

const ServiceData = [{
    id: 1,
    title: "Strategy",
    text : "Meticulously planning, executing, and optimizing technology initiatives to align seamlessly with business objectives, foster growth, and secure a competitive edge"
},
    {
        id: 2,
        title: "Engineering",
        text : "Platform & Product Development, DevOps, IoT, Open Source solutions, and API & Integration services to empower businesses with seamless digital experiences and scalable technology solutions"
    },
    {
        id: 3,
        title: "Cloud",
        text : "leveraging cloud solutions to streamline operations, enhance scalability, and optimize resource utilization for our clients"
    }
    ,
    {
        id: 4,
        title: "Data & Analytics",
        text: "Harnessing the power of data and analytics to drive informed decision-making, uncover valuable insights, and propel business growth"
    },
    {    id: 5,
         title: "Artificial Intelligence",
         text: "Human interaction with Responsible & Generative AI, ensuring AI Security by Design, and driving AI-Accelerated Engineering"
    },
    {
        id: 6,
        title: "Digital Transformation",
        text: "Strategic integration of digital technologies to revolutionize business processes, elevate customer experiences, and foster innovation "
    },
    {
        id: 7,
        title: "Visual identity",
        text: "Crafting compelling visual identities that bridge the virtual and material worlds, resonating with audiences, conveying brand values, and leaving a lasting impression across all touchpoints"
    }
    ,
    {
        id: 8,
        title: "Cybersecurity",
        text: "Ensuring robust protection against threats, safeguarding data integrity, and maintaining the trust of our clients and their customers"
    }
];

const ServiceCards = () => {
    return (
        <>
            {ServiceData.map(({ id, title, text }) => (
                <div key={id} className="card">
                    <div className="card-title">
                        <h1>{title}</h1>
                    </div>
                    <div className="description">
                        <p>{text}</p>
                    </div>
                </div>
            ))}
        </>
    );
};

export default ServiceCards;
