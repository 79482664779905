import './slider-page.css';

const CarouselPage = () =>{
    return(
        <>
            <div className="carousel-page">
                <div className="title">
                    <h1>One page site</h1><h6>Here you can <span>view our works </span></h6>
                </div>
            </div>
        </>
    )
}

export default CarouselPage;