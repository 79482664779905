import './work-images.css';
import ImagesDetails from "../../../helpers/project_images_data";

const WorkImages = () =>{
    return(
        <>
            <ImagesDetails/>
        </>
    )
}

export default WorkImages;